import vertexShaderSource from "../shaders/shader.vert";
import fragmentShaderSource from "../shaders/shader.frag";

const CANVAS_ID = "#canvas";

function smoothstep(t) {
  return t * t * (3 - 2 * t);
}

function lerp(start, end, t) {
  t = smoothstep(t);
  return start * (1 - t) + end * t;
}

function createBufferAndSetData(gl, data) {
  const buffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(data), gl.STATIC_DRAW);
  return buffer;
}

function setUpTexture(gl, image) {
  const texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
}

function resizeImage(image, width, height) {
  let canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  let context = canvas.getContext("2d");
  context.drawImage(image, 0, 0, width, height);
  return canvas;
}

function render(image) {
  const canvas = document.querySelector(CANVAS_ID);
  const gl =
    canvas.getContext("webgl") || canvas.getContext("experimental-webgl");

  const vertexShader = webglUtils.createShader(
    gl,
    gl.VERTEX_SHADER,
    vertexShaderSource
  );
  const fragmentShader = webglUtils.createShader(
    gl,
    gl.FRAGMENT_SHADER,
    fragmentShaderSource
  );
  const program = webglUtils.createProgram(gl, vertexShader, fragmentShader);
  gl.useProgram(program);

  webglUtils.resizeCanvasToDisplaySize(gl.canvas);
  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
  gl.clearColor(0, 0, 0, 0);
  gl.clear(gl.COLOR_BUFFER_BIT);

  const positionAttributeLocation = gl.getAttribLocation(program, "a_position");
  const texCoordLocation = gl.getAttribLocation(program, "a_texCoord");
  const u_resolutionLocation = gl.getUniformLocation(program, "u_resolution");
  gl.uniform2f(u_resolutionLocation, gl.canvas.width, gl.canvas.height);

  setUpTexture(gl, image);

  var resolutionLocation = gl.getUniformLocation(program, "u_resolution");

  gl.enableVertexAttribArray(positionAttributeLocation);
  gl.enableVertexAttribArray(texCoordLocation);
  gl.vertexAttribPointer(positionAttributeLocation, 2, gl.FLOAT, false, 0, 0);

  const height = canvas.height / 3.7; //mettre 3.7
  const width = height * 0.7;
  const sizeBetweenImage = 22;
  const rect = {
    width: width,
    height: height,
    y: canvas.height / 2 - height / 2,
  };

  const numberOfImage = 10;
  let positions = [];
  let positionBuffers = [];
  let texCoordBuffer = [];
  let wheelCount = 0;
  let positionXImages = [];

  for (let i = 0; i < numberOfImage; i++) {
    const x1 = rect.width * i + sizeBetweenImage * i;
    const x2 = rect.width * i + rect.width + sizeBetweenImage * i;
    const y1 = rect.y;
    const y2 = rect.y + rect.height;

    positionXImages[i] = {
      x1: x1,
      x2: x2,
      y1: y1,
      y2: y2,
    };

    let pos = [x1, y1, x2, y1, x1, y2, x1, y2, x2, y1, x2, y2];

    positions.push(pos);

    positionBuffers[i] = createBufferAndSetData(gl, positions[i]);
  }
  let tex = [0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1];
  texCoordBuffer = createBufferAndSetData(gl, tex);

  function updatePositions() {
    const LERP_SPEED = 0.9; // ajustez cette valeur pour changer la vitesse de l'interpolation
    wheelCount = lerp(0, wheelCount, LERP_SPEED);
    positions = [];
    positionBuffers = [];
    for (let i = 0; i < numberOfImage; i++) {
      positionXImages[i].x1 += wheelCount;
      positionXImages[i].x2 += wheelCount;
    }
    for (let i = 0; i < numberOfImage; i++) {
      if (positionXImages[i].x2 < 0) {
        let previousImageIndex = i === 0 ? numberOfImage - 1 : i - 1;
        positionXImages[i].x1 =
          positionXImages[previousImageIndex].x2 + sizeBetweenImage;
        positionXImages[i].x2 =
          positionXImages[previousImageIndex].x2 +
          rect.width +
          sizeBetweenImage;
      } else if (positionXImages[i].x1 > gl.canvas.width) {
        let nextImageIndex = i === numberOfImage - 1 ? 0 : i + 1;
        positionXImages[i].x1 =
          positionXImages[nextImageIndex].x1 - rect.width - sizeBetweenImage;
        positionXImages[i].x2 =
          positionXImages[nextImageIndex].x1 - sizeBetweenImage;
      }

      let pos = [
        positionXImages[i].x1,
        positionXImages[i].y1,
        positionXImages[i].x2,
        positionXImages[i].y1,
        positionXImages[i].x1,
        positionXImages[i].y2,
        positionXImages[i].x1,
        positionXImages[i].y2,
        positionXImages[i].x2,
        positionXImages[i].y1,
        positionXImages[i].x2,
        positionXImages[i].y2,
      ];

      positions.push(pos);
      positionBuffers[i] = createBufferAndSetData(gl, positions[i]);
    }
  }

  updatePositions();

  function drawRect() {
    gl.clear(gl.COLOR_BUFFER_BIT);

    gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
    gl.vertexAttribPointer(texCoordLocation, 2, gl.FLOAT, false, 0, 0);

    positions.forEach((position, positionIndex) => {
      gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffers[positionIndex]);
      gl.vertexAttribPointer(
        positionAttributeLocation,
        2,
        gl.FLOAT,
        false,
        0,
        0
      );
      gl.uniform2f(resolutionLocation, gl.canvas.width, gl.canvas.height);

      gl.drawArrays(gl.TRIANGLES, 0, position.length / 2);
    });
  }

  drawRect();

  function renderLoop() {
    updatePositions();
    drawRect();
    window.requestAnimationFrame(renderLoop);
  }

  window.addEventListener("wheel", function (e) {
    if (e.deltaY < 0) wheelCount += -5;
    else wheelCount += 5;
    if (wheelCount > 100) wheelCount = 100;
    if (wheelCount < -100) wheelCount = -100;
  });

  let mouseIsDown = false;
  let startX;

  window.addEventListener("mousedown", (e) => {
    mouseIsDown = true;
    startX = e.pageX / 25;
  });

  window.addEventListener("mouseup", () => {
    mouseIsDown = false;
  });

  window.addEventListener("mousemove", (e) => {
    if (mouseIsDown) {
      let deltaX = startX - e.pageX / 25;
      startX = e.pageX / 25;
      wheelCount += deltaX * -1;

      // Keep within bounds
      if (wheelCount > 100) wheelCount = 100;
      if (wheelCount < -100) wheelCount = -100;
    }
    let overImage = positionXImages.some(
      (pos) => e.pageY >= pos.y1 && e.pageY <= pos.y2
    );
    canvas.style.cursor = overImage ? "pointer" : "default";
  });

  window.addEventListener("keydown", (e) => {
    const KEY_SPEED = 5;
    switch (e.keyCode) {
      case 37: // left arrow key
        wheelCount -= KEY_SPEED;
        break;
      case 39: // right arrow key
        wheelCount += KEY_SPEED;
        break;
    }

    // Keep within bounds
    if (wheelCount > 100) wheelCount = 100;
    if (wheelCount < -100) wheelCount = -100;
  });
  window.addEventListener("contextmenu", function (e) {
    e.preventDefault();
  });

  renderLoop();
}

function main() {
  const image = new Image();
  image.src = require("../img/image1.webp");
  image.onload = () => render(image);
}

main();
